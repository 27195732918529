<template>
  <div class="Awarp">
    <dashCard class="energykanbanBox3" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
              @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('energykanbanBox3_total','设备总数', getZEdata)}}</template>
      <template slot="aside"></template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
             width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <energykanbanBox3 :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'energykanbanBox3',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6}
      }
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick(_ => {
          this.getOperateWidth()
          var dom = this.$refs['chart1']
          if (!!dom) {
            dom.handleWindowResize()
          }
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中

      searchObj: {},
      chartOption1: {},
      detailInfo: {
        chart1: {},
        list: []
      },
      colorList: ["#2D76FE", "#4DB8FF", "#FFB200", "#3E6DFF", "#536EBF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF"],
    }
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if (!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if (!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    handleChange(value) {
      this.getData()
    },
    //每月报警预警数
    getData() {
      const url = Config.dev_url + "/api-apps-v2/api/v2/equipment/statistics";
      var data = {
        deptCode: this.currentSelectDeptInfo.CODE
      }
      this.isLoading = true
      this.$axios.post(url, data).then(res => {
        if (res.data.code == 0) {
          var chart1 = {
            seriesData: []
          }
          res.data.data.forEach(item => {
            var obj = {
              name: item.machine_type,
              value: item.total,
              itemStyle: {}
            }
            chart1.seriesData.push(obj)
          })
          let lang = sessionStorage.getItem("locale") || 'CH'
            
            if(lang=='EN'){
              for(let item of chart1.seriesData){
                  if(item.name =='储能BMS'){
                      item.name = 'Energy storage BMS'
                  }
                  if(item.name =='储能PCS'){
                      item.name = 'Energy storage PCS'
                  }
                  if(item.name =='充电桩'){
                      item.name = 'Charging station'
                  }
                  if(item.name =='光伏逆变器'){
                      item.name = 'PV Inverter'
                  }
                  if(item.name =='动环监控'){
                      item.name = 'environment supervision'
                  }
                  if(item.name =='发动机控制器'){
                      item.name = 'Engine controller'
                  }
                  if(item.name =='探测器'){
                      item.name = 'detector'
                  }
                  if(item.name =='柴发控制器'){
                      item.name = 'Chaifa controller'
                  }
                  if(item.name =='桩表'){
                      item.name = 'Pile table'
                  }
                  if(item.name =='消防设备'){
                      item.name = 'fire-fighting equipment'
                  }
                  if(item.name =='温湿度传感器'){
                      item.name = 'Temperature and Humidity Sensor'
                  }
                  if(item.name =='电表'){
                      item.name = 'electricity meter'
                  }
                  if(item.name =='空调'){
                      item.name = 'air-conditioning'
                  }
              }
            }
          this.detailInfo.chart1 = chart1
          this.initEchart()
        } else {
          // this.$message.warning(res.data.msg)
        }
      }).catch((wrong) => {
      }).finally(() => {
        this.isLoading = false
      })
    },
    getData0() {
      var chart1 = {
        seriesData: [
          {
            name: "园区一",
            value: 35
          },
          {
            name: "园区二",
            value: 30,
          },
          {
            name: "园区三",
            value: 22,
          },
        ]
      }
      this.detailInfo.chart1 = chart1
      this.initEchart()
    },
    initEchart() {
      var {seriesData} = this.detailInfo.chart1

      var sum = seriesData.reduce(function (prev, cur) {
        return prev + Number(cur.value);
      }, 0)
      for (let item of seriesData) {
        console.log(item)
        if (item.name == '配电') {
          item.itemStyle.color = '#c6c6c6'
        } else if (item.name == '光伏') {
          item.itemStyle.color = '#42ff48'
        } else if (item.name == '储能') {
          item.itemStyle.color = '#61a858'
        }
      }

      sum = this.toThousandsSeparator(sum)
      var colorList = this.colorList

      var option = {
        color: colorList,
        animation: true,
        layoutAnimation: false,
        title: [
          {
            text: "",
            subtext: sum,
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: 'ABBvoice_WCNSG_Rg',
            },
            subtextStyle: {
              fontSize: 24,
              fontFamily: 'ABBvoice_WCNSG_Rg',
              color: "#1D2129",
              fontWeight: '600',
            },
            textAlign: "center",

            x: "50%",
            y: "33%",
          },
          {
            text: this.LangHandler('totalnumber','设备总数（台）'),
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: 'ABBvoice_WCNSG_Rg',
            },
            textAlign: "center",

            x: "50%",
            y: "39%",
          }
        ],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: '15%',
          right: '15%',
          bottom: '15%',
          // orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: 'ABBvoice_WCNSG_Rg',
            color: '#1d2129'
          },
        },


        // title: [
        //     {
        //     text: '1163',
        //     x: '33%',
        //     y: '40%',
        //     textAlign: 'center',
        //     textStyle: {
        //         fontSize: '18',
        //         fontWeight: '600',
        //         color: 'black',
        //         textAlign: 'center',
        //     },
        // },
        // {
        //     text: '总计',
        //     x: '33%',
        //     y: '50%',
        //     textAlign: 'center',
        //     textStyle: {
        //         fontSize: '16',
        //         fontWeight: '600',
        //         color: 'black',
        //         textAlign: 'center',
        //     },
        // }
        // ],
        series: [{
          itemStyle: {
            normal: {
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          //clockWise: false ,
          //roseType: 'radius',
          radius: ['70%', '80%'],
          center: ["50%", "37%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: false,
              formatter: params => {
                return (
                    '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                );
              },

              padding: [0, -5, 0, -5],
            }
          },
          data: seriesData
        },
          //   {
          //     itemStyle: {
          //         normal: {
          //             color: 'rgba(119,140,162,0.08)',
          //         }
          //     },
          //     type: 'pie',
          //     hoverAnimation: false,
          //     radius: ['65%', '85%'],
          //     center: ["50%", "35%"],
          //     label: {
          //         normal: {
          //             show:false
          //         }
          //     },
          //     data: [{
          //             value: 1,
          //         }],
          //     z:-1
          // }
        ]
      }
      this.updateChart('chart1', 'chartOption1', option)
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return
      this[optionName] = option
      setTimeout(() => {
        this.$refs[refName].updateChartView()
      }, 500)
    },

  },

  mounted() {
    this.getOperateWidth()
    if (!this.option.visible) {
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.energykanbanBox3 {
  .card-content1 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
